import Vue from 'vue'

import {
    Form,
    FormItem,
    Input,
    Tooltip,
    Dialog,
    Button,
    Container,
    Aside,
    Header,
    Main,
    Breadcrumb,
    BreadcrumbItem,
    Upload,
    Table,
    TableColumn,
    Loading,
    Pagination,
    Select,
    Tree,
    Option,
    Image,
    Switch,
    Message,
    MessageBox,
    DatePicker,
    Scrollbar,
    Radio,
    RadioGroup
} from 'element-ui';

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Upload)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Tree)
Vue.use(Option)
Vue.use(Image)
Vue.use(Switch)
Vue.use(DatePicker)
Vue.use(Scrollbar)
Vue.use(Radio)
Vue.use(RadioGroup)
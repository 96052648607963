<template>
  <el-container>
    <el-aside>
      <el-scrollbar style="">
        <Sidebar></Sidebar>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header></el-header>
      <el-main>
        <AppMain></AppMain>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { Sidebar, AppMain } from "./components";

export default {
  name: "Layout",
  components: {
    Sidebar,
    AppMain,
  },
};
</script>

<style lang="scss">
.el-aside {
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  background: #1c1c1e;
  width: 126px !important;
  .el-scrollbar {
    width: 100%;
    height: 100%;
  }
}
.el-main {
  padding: 0 !important;
  margin: 0 !important;
  .el-button {
    height: 36px !important;
    padding: 10px 20px !important;
    box-sizing: border-box !important;
  }
}
.el-header {
  height: 50px !important;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
}

.el-table {
  width: calc(100% - 16px) !important;
  .icon {
    font-size: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
  .el-table__cell {
    padding: 10px 0 !important;
  }
}

.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>

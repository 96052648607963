import { getMenu } from "@/api/system/modules";
import { getUid } from '@/utils/auth'

const state = {
    menu: []
}

const mutations = {
    SET_MENU: (state, menu) => {
        state.menu = menu
    },
}

const actions = {
    getMenu({ commit }) {
        return new Promise((resolve, reject) => {
            getMenu({ uid: getUid() }).then(response => {
                const { data } = response
                commit('SET_MENU', data)
                console.log('获取了菜单');
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
<template>
  <div class="app-main">
    <Secondary></Secondary>
    <router-view></router-view>
  </div>
</template>

<script>
import Secondary from "../components/Secondary.vue";

export default {
  name: "AppMain",
  components: {
    Secondary,
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  overflow: hidden;
  display: flex;
  background: #f5f5f5;
}
</style>
import { login, logout, getInfo } from '@/api/system/admin'
import { getToken, setToken, removeToken, getUid, setUid, removeUid } from '@/utils/auth'

const state = {
    token: getToken(),
    uid: getUid(),
    marketInfo: 'null',
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            login({ uname: username.trim(), upass: password }).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                setUid(data.uid)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken()
            removeUid()
            resolve()
        })
    },

    // get market info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo({ uid: getUid() }).then(response => {
                const { data } = response
                console.log(data);
                commit('SET_INFO', data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INFO: (state, marketInfo) => {
        state.marketInfo = marketInfo
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

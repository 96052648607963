<template>
  <div class="menu">
    <div class="logo">
      <el-image
        v-if="marketInfo.logoUrl"
        :src="marketInfo.logoUrl"
        :preview-src-list="[marketInfo.logoUrl]"
      >
      </el-image>
    </div>
    <div
      class="menu_base"
      v-for="(item, i) in menu"
      :key="i"
      :class="activeUrl === item.url ? 'active' : ''"
      @click="activeMenus(item)"
    >
      <div class="icon fa" :class="item.fonticon"></div>
      <div>{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeUrl: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.searchMenu(val.matched[1].path);
      },
    },
    menu(val) {
      this.searchMenu(this.$route.matched[1].path);
    },
  },
  methods: {
    searchMenu(url) {
      const data = this.menu;
      data.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((i) => {
            if (i.url == url) {
              this.activeUrl = item.url;
              return;
            }
          });
        }
      });
    },
    activeMenus(item) {
      if (!item.children) {
        this.$message.error("该菜单没有内容");
      } else {
        this.$router.push(item.children[0].url);
      }
    },
  },
  computed: {
    ...mapGetters(["menu", "marketInfo"]),
  },
};
</script>

<style lang="scss" scoped>
.menu {
  color: #fff;
  font-size: 13px;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  .logo {
    width: 126px;
    height: 80px;
    ::v-deep .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-left: 35px;
      margin-top: 17px;
    }
  }
  &_base {
    display: flex;
    align-items: center;
    width: 126px;
    height: 50px;
    padding-left: 15px;
    box-sizing: border-box;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 100%;
      line-height: 53px;
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
.active {
  background: #fff;
  color: #5874d8;
}

.active {
  position: relative;
}
.active::before {
  content: "";
  position: absolute;
  right: -1px;
  width: 152px;
  height: 10px;
  background: #1c1c1e;
  border-bottom-right-radius: 10px;
  top: -6px;
}
.active::after {
  content: "";
  position: absolute;
  right: -1px;
  width: 152px;
  height: 10px;
  background: #1c1c1e;
  border-top-right-radius: 10px;
  bottom: -6px;
}
</style>

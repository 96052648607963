import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { mixin } from './mixins/mixin';
Vue.mixin(mixin);

import './permission' // permission control
import './icons' // icon
import 'font-awesome/css/font-awesome.min.css';
import 'element-tiptap/lib/index.css';// 富文本编辑器


Vue.config.productionTip = false
import './elementUI'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUid() {
  return localStorage.getItem('uid');
}

export function setUid(uid) {
  return localStorage.setItem('uid', uid)
}

export function removeUid() {
  return localStorage.removeItem('uid')
}




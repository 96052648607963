<template>
  <div class="menu_active">
    <ul class="secondary">
      <li
        v-for="(item, i) in SecondaryMenu"
        :key="i"
        :class="activeUrl === item.url ? 'active' : ''"
        @click="handleLink(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Secondary",
  data() {
    return {
      SecondaryMenu: [],
      activeUrl: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(val) {
        this.searchMenu(val.matched[1].path);
        this.activeUrl = val.matched[1].path;
      },
    },
    menu(val) {
      this.searchMenu(this.$route.matched[1].path);
    },
  },
  computed: {
    ...mapGetters(["menu"]),
  },
  methods: {
    searchMenu(url) {
      const data = this.menu;
      data.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((i) => {
            if (i.url == url) {
              this.SecondaryMenu = item.children;
              return;
            }
          });
        }
      });
    },
    handleLink(item) {
      this.activeName = item.name;
      this.$router.push(item.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_active {
  width: 120px;
  background: #fff;
  border-right: 1px solid #eee;
  color: #333;
  font-size: 12px;
  text-align: center;

  .secondary {
    margin-top: 20px;
    padding: 0;
    width: 100%;
    list-style: none;
    li {
      width: 96px;
      height: 32px;
      line-height: 32px;
      margin: 0 auto;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .active {
      background: rgb(230, 235, 255);
      color: #5874d8;
    }
  }
}
</style>

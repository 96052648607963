import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from localStorage

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, form, next) => {
  // 判断用户是否已经登录
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // 如果已登录，则重定向到主页
      next({ path: '/' })
    } else {

      if (store.getters.menu.length) {
        if (store.getters.marketInfo === 'null') {
          try {
            // 获取市场基本信息
            await store.dispatch('admin/getInfo')
          } catch (error) {
            // 删除令牌并转到登录页面重新登录
            await store.dispatch('admin/resetToken')
            next(`/login`)
          }
        }
        next()
      } else {
        try {
          // 获取后台菜单
          await store.dispatch('menu/getMenu')
          // 获取市场基本信息
          await store.dispatch('admin/getInfo')
          next()
        } catch (error) {
          // 删除令牌并转到登录页面重新登录
          await store.dispatch('admin/resetToken')
          next(`/login`)
        }
      }

    }
  } else {
    if (whiteList.indexOf(to.path) == 0) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`)
    }
  }
})
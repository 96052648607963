import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/market/information',
    },
    {
        path: '/login',
        component: () => import('@/views/login/index'),
    },
    {
        path: '/market',
        component: Layout,
        redirect: '/market/information',
        name: 'Market',
        meta: {
            title: '市场管理',
        },
        children: [
            {
                path: 'information',
                component: () => import('@/views/market/information'),
                name: 'Information',
                meta: {
                    title: '基本信息',
                }
            },
            {
                path: 'area',
                component: () => import('@/views/market/area'),
                name: 'Area',
                meta: {
                    title: '区域设置'
                }
            },
            {
                path: 'classify',
                component: () => import('@/views/market/classify'),
                name: 'Classify',
                meta: {
                    title: '分类设置',
                }
            },
            {
                path: 'tagSetting',
                component: () => import('@/views/market/tagSetting'),
                name: 'TagSetting',
                meta: {
                    title: '标签设置',
                }
            },
        ]
    },
    {
        path: '/supplier',
        component: Layout,
        redirect: '/supplier/supplierList',
        name: 'Supplier',
        meta: {
            title: '商户管理',
        },
        children: [
            {
                path: 'supplierList',
                component: () => import('@/views/supplier/supplierList'),
                name: 'SupplierList',
                meta: {
                    title: '全部商家',
                }
            },
        ]
    },
    {
        path: '/system',
        component: Layout,
        redirect: '/system/modulesList',
        name: 'System',
        meta: {
            title: '系统管理',
        },
        children: [
            {
                path: 'adminList',
                component: () => import('@/views/system/adminList'),
                name: 'AdminList',
                meta: {
                    title: '管理员管理',
                }
            },
            {
                path: 'roleList',
                component: () => import('@/views/system/roleList'),
                name: 'RoleList',
                meta: {
                    title: '角色管理',
                }
            },
            {
                path: 'nodeList',
                component: () => import('@/views/system/nodeList'),
                name: 'NodeList',
                meta: {
                    title: '节点管理',
                }
            },
            {
                path: 'modulesList',
                component: () => import('@/views/system/modulesList'),
                name: 'ModulesList',
                meta: {
                    title: '菜单列表',
                }
            },
        ]
    },
    {
        path: '/rests',
        component: Layout,
        redirect: '/rests/cmsCategorys',
        name: 'Rests',
        meta: {
            title: '其他管理',
        },
        children: [
            {
                path: 'cmsCategorys',
                component: () => import('@/views/rests/cmsCategorys'),
                name: 'CmsCategorys',
                meta: {
                    title: '分类管理',
                }
            },
            {
                path: 'cmsArticle',
                component: () => import('@/views/rests/cmsArticle'),
                name: 'CmsArticle',
                meta: {
                    title: '文章管理',
                }
            },
            {
                path: 'adJingang',
                component: () => import('@/views/rests/adJingang'),
                name: 'AdJingang',
                meta: {
                    title: '金刚区管理',
                }
            },
            {
                path: 'cmsTpl',
                component: () => import('@/views/rests/cmsTpl'),
                name: 'CmsTpl',
                meta: {
                    title: '栏目模板管理',
                }
            },
            {
                path: 'adList',
                component: () => import('@/views/rests/adList'),
                name: 'AdList',
                meta: {
                    title: '广告列表',
                }
            },
            {
                path: 'adArea',
                component: () => import('@/views/rests/adArea'),
                name: 'AdArea',
                meta: {
                    title: '广告区域',
                }
            },
        ]
    },
    {
        path: '/user',
        component: Layout,
        redirect: '/user/userList',
        name: 'User',
        meta: {
            title: '用户管理',
        },
        children: [
            {
                path: 'userList',
                component: () => import('@/views/user/userList'),
                name: 'UserList',
                meta: {
                    title: '用户列表',
                }
            },
            {
                path: 'userTrends',
                component: () => import('@/views/user/userTrends'),
                name: 'UserTrends',
                meta: {
                    title: '用户动态',
                }
            },
            {
                path: 'trendsComment',
                component: () => import('@/views/user/trendsComment'),
                name: 'TrendsComment',
                meta: {
                    title: '动态评论',
                }
            },
        ]
    },
]

const router = new Router({
    // 配置路由和组件之间的应用关系
    routes,
    mode: "hash",
    linkActiveClass: "active",
});

// 处理重复路由
const originpush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originpush.call(this, location).catch(err => err)
}

export default router;
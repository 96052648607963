import request from '@/utils/request'

export function getMenu(params) {
    return request({
        url: '/agent/adminModules/getList',
        method: 'get',
        params
    })
}

export function modulesList(params) {
    return request({
        url: '/agent/adminModules/menuList',
        method: 'get',
        params
    })
}

export function Update(data) {
    return request({
        url: '/agent/adminModules/update',
        method: 'post',
        data
    })
}

export function Delete(params) {
    return request({
        url: '/agent/adminModules/delete',
        method: 'get',
        params
    })
}

export function modulesDisplay(data) {
    return request({
        url: '/agent/adminModules/display',
        method: 'post',
        data
    })
}
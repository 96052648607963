export const mixin = {
    methods: {
        //拼接字符串
        joinId(param, row) {
            var ids = "";
            if (row) {
                ids = row[param];
            } else {
                if (!this.$refs.mulSelTable.selection.length) {
                    this.$message.error("请选择操作数据");
                    this.dialogVisible = false;
                    this.loading = false;
                    return;
                }
                let selection = this.$refs.mulSelTable.selection;
                selection.forEach((item) => {
                    if (ids) {
                        ids = ids + "," + item[param];
                    } else {
                        ids = item[param];
                    }
                });
            }
            return ids;
        },
        // 公用删除框
        openDel(row) {
            this.$confirm("是否删除选中数据？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.Delete(row);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    }
}